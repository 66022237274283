<template>
  <div v-if="$store.state.fastPermissoes.ativo == 'S' && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.professor == 'S')">
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a                
                class="aluno_font_color"
                href="#"
              >Modo admin</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-0">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 col-lg-12 fd-mode-adm-content-body"
              style="margin-left: 0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Bem vindo,
                  <span>{{ $store.state.fastUsuario.First_name }}</span>
                </h1>
                <p class="aluno_font_color">
                  Pensamos em tudo para facilitar o seu trabalho
                </p>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <!-- fd-mode-opcoes -->
              <section class="fd-mode-opcoes">
                <!-- Dashboard -->
                <div class="fd-mode-opcoes-item position-relative">
                  <div class="row">
                    <a
                      :href="
                        '/plataforma/' +
                          $route.params.id_plataforma +
                          '/admin/dashboard'
                      "
                      class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                      :style="{
                        backgroundColor: $store.state.fastPersonalizacao
                          .menu_background_color
                          ? $store.state.fastPersonalizacao
                            .menu_background_color
                          : '#fff',
                      }"
                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/dashboard')"
                    >
                      <img
                        :src="imageSiteMatrizIcon"
                        class="img-fluid"
                      >
                      <h3 class="mt-0 menu_font_color">Dashboard</h3>
                    </a>
                  </div>
                  <div
                    class="fd-mode-opcoes-item-dicas alert alert-info"
                    style="display: none"
                    role="alert"
                  >
                    <i class="fas fa-info-circle" /> Visualize dados
                    estatísticos da sua plataforma.
                  </div>
                </div>
                <!-- personalizar -->
                <div
                  v-if="$store.state.fastPermissoes.edita_plataforma == 'S'"
                  class="fd-mode-opcoes-item position-relative"
                  data-toggle="collapse"
                  href="#collapseBoxPersonalizar"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseBoxPersonalizar"
                  dont-toogle-lg
                >
                  <div class="row align-items-center">
                    <a
                      
                      class="col-sm-12 col-md-12 col-lg-8"
                      :style="{
                        backgroundColor: $store.state.fastPersonalizacao
                          .menu_background_color
                          ? $store.state.fastPersonalizacao
                            .menu_background_color
                          : '#fff',
                      }"
                    >
                      <div
                        class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                      >
                        <img
                          :src="imageSitePersonalizarIcon" 
                          class="img-fluid"
                        >
                        <h3 class="menu_font_color">Personalizar</h3>
                      </div>
                    </a>

                    <div
                      id="collapseBoxPersonalizar"
                      class="col-sm-12 col-md-12 col-lg-4 fd-mode-opcoes-item-menu"
                    >
                      <ul class="fd-mode-opcoes-item-menu-list">
                        <li
                          class="fd-mode-opcoes-item-menu-list-item-multi d-flex"
                        >
                          <a
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="
                              '/plataforma/' +
                                $route.params.id_plataforma +
                                '/admin/personalizar-plataforma'
                            "
                            @click.prevent="$router.push('/plataforma/' +
                              $route.params.id_plataforma +
                              '/admin/personalizar-plataforma')"
                          >Plataforma</a>
                          <a
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="
                              '/plataforma/' +
                                $route.params.id_plataforma +
                                '/admin/personalizar-certificados'
                            "
                            @click.prevent="$router.push('/plataforma/' +
                              $route.params.id_plataforma +
                              '/admin/personalizar-certificados')"
                          >Certificados</a>
                        </li>
                        <li
                          class="fd-mode-opcoes-item-menu-list-item-multi d-flex"
                        >
                          <a
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="
                              '/plataforma/' +
                                $route.params.id_plataforma +
                                '/admin/personalizar-ranking'
                            "
                            @click.prevent="$router.push('/plataforma/' +
                              $route.params.id_plataforma +
                              '/admin/personalizar-ranking')"
                          >Ranking</a>
                          <a
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="
                              '/plataforma/' +
                                $route.params.id_plataforma +
                                '/admin/personalizar-medalhas'
                            "
                            @click.prevent="$router.push('/plataforma/' +
                              $route.params.id_plataforma +
                              '/admin/personalizar-medalhas')"
                          >Medalhas</a>
                          <a
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="
                              '/plataforma/' +
                                $route.params.id_plataforma +
                                '/admin/tutoriais'
                            "
                            @click.prevent="$router.push('/plataforma/' +
                              $route.params.id_plataforma +
                              '/admin/tutoriais')"
                          >Tutoriais</a>
                        </li>
                        <li
                          class="fd-mode-opcoes-item-menu-list-item-multi d-flex"
                        >
                          <a
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="
                              '/plataforma/' +
                                $route.params.id_plataforma +
                                '/admin/personalizar-emails'
                            "
                            @click.prevent="$router.push('/plataforma/' +
                              $route.params.id_plataforma +
                              '/admin/personalizar-emails')"
                          >Emails</a>
                          <a
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="
                              '/plataforma/' +
                                $route.params.id_plataforma +
                                '/admin/provas'
                            "
                            @click.prevent="$router.push('/plataforma/' +
                              $route.params.id_plataforma +
                              '/admin/provas')"
                          >Provas</a>
                          <a
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="
                              '/plataforma/' +
                                $route.params.id_plataforma +
                                '/admin/personalizar-disciplinas'
                            "
                            @click.prevent="$router.push('/plataforma/' +
                              $route.params.id_plataforma +
                              '/admin/personalizar-disciplinas')"
                          >Disciplinas</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- integrações -->
                <div 
                  class="fd-mode-opcoes-item position-relative"
                  data-toggle="collapse"
                  href="#collapseBoxUsuarios"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseBoxUsuarios"
                  dont-toogle-lg
                >
                  <div class="row align-items-center">
                    <a
                      class="col-sm-12 col-md-12 col-lg-8 con-classroom-anotacoes-desativado shadow rounded"
                      :style="{
                        backgroundColor: $store.state.fastPersonalizacao
                          .menu_background_color
                          ? $store.state.fastPersonalizacao
                            .menu_background_color
                          : '#fff',
                      }"
                    >
                      <div
                        class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                      >
                        <img
                          :src="imageSiteIntegracoes"
                          class="img-fluid"
                        >
                        <h3 class="menu_font_color">Integrações</h3>
                      </div>
                    </a>

                    <div
                      id="collapseBoxUsuarios"
                      class="col-sm-12 col-md-12 col-lg-4 fd-mode-opcoes-item-menu"
                    >
                      <ul class="fd-mode-opcoes-item-menu-list">
                        <li class="fd-mode-opcoes-item-menu-list-item-multi d-flex">
                          <a
                            v-if="$store.state.fastPermissoes.edita_admins == 'S'"
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="'/plataforma/' + $route.params.id_plataforma +'/admin/assinatura-documentos'"
                            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma +'/admin/assinatura-documentos')"
                          >Gateways de autenticação</a>
                        </li>
                        <li class="fd-mode-opcoes-item-menu-list-item-multi d-flex">
                          <a
                            v-if="$store.state.fastPermissoes.edita_admins == 'S'"
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="'/plataforma/' + $route.params.id_plataforma +'/admin/gateways-pagamento'"
                            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma +'/admin/gateways-pagamento')"
                          >Gateways de pagamento</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- usuários -->
                <div 
                  class="fd-mode-opcoes-item position-relative"
                  data-toggle="collapse"
                  href="#collapseBoxUsuarios"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseBoxUsuarios"
                  dont-toogle-lg
                >
                  <div class="row align-items-center">
                    <a
                      class="col-sm-12 col-md-12 col-lg-8 con-classroom-anotacoes-desativado shadow rounded"
                      :style="{
                        backgroundColor: $store.state.fastPersonalizacao
                          .menu_background_color
                          ? $store.state.fastPersonalizacao
                            .menu_background_color
                          : '#fff',
                      }"
                    >
                      <div
                        class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                      >
                        <img
                          :src="imageSiteUsuariosIcon"
                          class="img-fluid"
                        >
                        <h3 class="menu_font_color">Usuários</h3>
                      </div>
                    </a>

                    <div
                      id="collapseBoxUsuarios"
                      class="col-sm-12 col-md-12 col-lg-4 fd-mode-opcoes-item-menu"
                    >
                      <ul class="fd-mode-opcoes-item-menu-list">
                        <li class="fd-mode-opcoes-item-menu-list-item-multi d-flex">
                          <a
                            v-if="$store.state.fastPermissoes.edita_admins == 'S'"
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="'/plataforma/' + $route.params.id_plataforma +'/admin/administradores'"
                            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma +'/admin/administradores')"
                          >Administradores</a>
                          <a
                            v-if="$store.state.fastPlataformaModulos.modulo_secretaria && $store.state.fastPermissoes.edita_usuarios == 'S'"
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="'/plataforma/' + $route.params.id_plataforma +'/admin/secretarios'"
                            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma +'/admin/secretarios')"
                          >Secretários</a>
                        </li>
                        <li                          
                          id="div_modo_admin_usuarios_professores"
                          class="fd-mode-opcoes-item-menu-list-item-multi d-flex"
                        >
                          <a
                            v-if="$store.state.fastPermissoes.edita_professores == 'S'"
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="'/plataforma/' + $route.params.id_plataforma + '/admin/professores'"
                            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/professores')"
                          >Professores</a>
                          <a
                            v-if="$store.state.fastPlataformaModulos.modulo_secretaria && $store.state.fastPermissoes.edita_usuarios == 'S'"
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="'/plataforma/' + $route.params.id_plataforma +'/admin/tesoureiros'"
                            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma +'/admin/tesoureiros')"
                          >Tesoureiros</a>
                        </li>                        
                        <li
                          class="fd-mode-opcoes-item-menu-list-item-multi d-flex"
                        >
                          <a
                            v-if="$store.state.fastPermissoes.edita_alunos == 'S'"
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="'/plataforma/' + $route.params.id_plataforma + '/admin/alunos'"
                            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/alunos')"
                          >Alunos</a>
                          <a
                            v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
                            class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                            :href="'/plataforma/' + $route.params.id_plataforma +'/admin/responsaveis'"
                            @click.prevent="exibeToasty('Acesso não autorizado', 'info')"
                          >Responsáveis</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- cursos -->
                <div
                  v-if="$store.state.fastPermissoes.edita_cursos == 'S'"
                  class="fd-mode-opcoes-item position-relative"
                  data-toggle="collapse"
                  href="#collapseBoxCursos"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseBoxCursos"
                  dont-toogle-lg
                >
                  <div class="row align-items-center">
                    <a
                      :href="
                        '/plataforma/' +
                          $route.params.id_plataforma +
                          '/admin/meus-cursos'
                      "
                      class="col-sm-12 col-md-12 col-lg-8 con-classroom-anotacoes-desativado shadow rounded"
                      :style="{
                        backgroundColor: $store.state.fastPersonalizacao
                          .menu_background_color
                          ? $store.state.fastPersonalizacao
                            .menu_background_color
                          : '#fff',
                      }"
                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/meus-cursos')"
                    >
                      <div
                        class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                      >
                        <img
                          v-if="
                            ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_meus_cursos)
                          "
                          :src="
                          ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_meus_cursos)
                          "
                          class="img-fluid"
                          style="max-width: 112px"
                        >
                        <img
                          v-else
                          :src="require('@/assets/images/app/cursos-icon.png')"
                          class="img-fluid"
                        >
                        <h3
                          v-if="
                            $store.state.fastPersonalizacao.meus_cursos_nome
                          "
                          class="mt-0 mt-sm-1 menu_font_color"
                        >
                          {{ $store.state.fastPersonalizacao.meus_cursos_nome }}
                        </h3>
                        <h3
                          v-else
                          class="mt-0 mt-sm-1 menu_font_color"
                        >
                          Meus Cursos
                        </h3>
                      </div>
                    </a>
                  </div>
                  <div
                    class="fd-mode-opcoes-item-dicas alert alert-info"
                    style="display: none"
                    role="alert"
                  >
                    <i class="fas fa-info-circle" /> Busque cursos disponíveis
                    do Fastead e/ou crie seus próprios cursos.
                  </div>
                </div>

                <div
                  v-if="$store.state.fastPermissoes.edita_matriz == 'S'"
                  id="div_modo_jobs"
                  class="fd-mode-opcoes-item position-relative"
                  data-toggle="collapse"
                  href="#collapseBoxMatriz"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseBoxMatriz"
                  dont-toogle-lg
                >
                  <div class="row align-items-center">
                    <a
                      :href="'/plataforma/' + $route.params.id_plataforma + '/admin/jobs'"
                      class="col-sm-12 col-md-12 col-lg-8 con-classroom-anotacoes-desativado shadow rounded"
                      :style="{
                        backgroundColor: $store.state.fastPersonalizacao
                          .menu_background_color
                          ? $store.state.fastPersonalizacao
                            .menu_background_color
                          : '#fff',
                      }"
                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/jobs')"
                    >
                      <div
                        class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                      >
                        <img
                          v-if="
                            ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_jobs)
                          "
                          :src="
                            ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_jobs)
                          "
                          class="img-fluid"
                          style="max-width: 112px"
                        >
                        <img
                          v-else
                          :src="
                            require('@/assets/images/app/menu_rotinas_icon.png')
                          "
                          class="img-fluid"
                        >
                        <h3
                          class="mt-0 mt-sm-1 menu_font_color"
                        >
                          Configurar Jobs (Rotinas)
                        </h3>
                      </div>
                    </a>

                    <div
                      id="collapseBoxMatriz"
                      class="col-sm-12 col-md-12 col-lg-4 fd-mode-opcoes-item-menu"
                    />
                  </div>
                  <div
                    class="fd-mode-opcoes-item-dicas alert alert-info"
                    role="alert"
                    style="display: none"
                  >
                    <i class="fas fa-info-circle" /> Cadastre os setores e
                    cargos da sua empresa.
                    <br>
                    <i class="fas fa-info-circle" /> Crie uma trilha de cursos
                    para cada cargo.
                    <br>
                  </div>
                </div>
                <div
                  v-if="$store.state.fastPermissoes.edita_matriz == 'S'"
                  id="div_modo_admin_capacitacao"
                  class="fd-mode-opcoes-item position-relative"
                  data-toggle="collapse"
                  href="#collapseBoxMatriz"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseBoxMatriz"
                  dont-toogle-lg
                >
                  <div class="row align-items-center">
                    <a
                      :href="'/plataforma/' + $route.params.id_plataforma + '/admin/matriz-capacitacao'"
                      class="col-sm-12 col-md-12 col-lg-8 con-classroom-anotacoes-desativado shadow rounded"
                      :style="{
                        backgroundColor: $store.state.fastPersonalizacao
                          .menu_background_color
                          ? $store.state.fastPersonalizacao
                            .menu_background_color
                          : '#fff',
                      }"
                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/matriz-capacitacao')"
                    >
                      <div
                        class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                      >
                        <img
                          v-if="
                            ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_minhas_capacitacoes)
                          "
                          :src="
                            ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_minhas_capacitacoes)
                          "
                          class="img-fluid"
                          style="max-width: 112px"
                        >
                        <img
                          v-else
                          :src="
                            require('@/assets/images/app/capacitacoes-icon.png')
                          "
                          class="img-fluid"
                        >
                        <h3
                          v-if="
                            $store.state.fastPersonalizacao.minhas_capacitacoes_nome
                          "
                          class="mt-0 mt-sm-1 menu_font_color"
                        >
                          {{
                            $store.state.fastPersonalizacao.minhas_capacitacoes_nome
                          }}
                        </h3>
                        <h3
                          v-else
                          class="mt-0 mt-sm-1 menu_font_color"
                        >
                          Minhas capacitacoes
                        </h3>
                      </div>
                    </a>

                    <div
                      id="collapseBoxMatriz"
                      class="col-sm-12 col-md-12 col-lg-4 fd-mode-opcoes-item-menu"
                    />
                  </div>
                  <div
                    class="fd-mode-opcoes-item-dicas alert alert-info"
                    role="alert"
                    style="display: none"
                  >
                    <i class="fas fa-info-circle" /> Cadastre os setores e
                    cargos da sua empresa.
                    <br>
                    <i class="fas fa-info-circle" /> Crie uma trilha de cursos
                    para cada cargo.
                    <br>
                  </div>
                </div>
                <div
                  id="div_modo_admin_cursos_ondemand"
                  class="fd-mode-opcoes-item position-relative"
                >
                  <div class="row">
                    <a
                      :href="'/loja-virtual/' + $route.params.id_plataforma"
                      class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                      :style="{
                        backgroundColor: $store.state.fastPersonalizacao
                          .menu_background_color
                          ? $store.state.fastPersonalizacao
                            .menu_background_color
                          : '#fff',
                      }"
                      @click.prevent="$router.push('/loja-virtual/' + $route.params.id_plataforma)"
                    >
                      <img
                        v-if="
                          ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_loja_plataforma)
                        "
                        :src="
                        ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_loja_plataforma)
                        "
                        class="img-fluid"
                        style="max-width: 112px"
                      >
                      <img
                        v-else
                        :src="require('@/assets/images/app/ondemand-icon.png')"
                        class="img-fluid"
                      >
                      <h3
                        v-if="
                          $store.state.fastPersonalizacao.loja_plataforma_nome
                        "
                        class="mt-0 mt-sm-1 menu_font_color"
                      >
                        {{
                          $store.state.fastPersonalizacao.loja_plataforma_nome
                        }}
                      </h3>
                      <h3
                        v-else
                        class="mt-0 mt-sm-1 menu_font_color"
                      >
                        Loja da plataforma
                      </h3>
                    </a>
                  </div>
                  <div
                    class="fd-mode-opcoes-item-dicas alert alert-info"
                    role="alert"
                    style="display: none"
                  >
                    <i class="fas fa-info-circle" /> Pesquisa e faça requisições
                    de cursos prontos para a sua plataforma.
                    <br>
                  </div>
                </div>

                <div
                  id="div_modo_admin_mural"
                  class="fd-mode-opcoes-item position-relative"
                >
                  <div class="row">
                    <a
                      :href="
                        '/plataforma/' +
                          $route.params.id_plataforma +
                          '/admin/mural'
                      "
                      class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                      :style="{
                        backgroundColor: $store.state.fastPersonalizacao
                          .menu_background_color
                          ? $store.state.fastPersonalizacao
                            .menu_background_color
                          : '#fff',
                      }"
                      @click.prevent="$router.push('/plataforma/' +
                        $route.params.id_plataforma +
                        '/admin/mural')"
                    >
                      <img
                        v-if="$store.state.fastPersonalizacao.menu_icone_mural"
                        :src="ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_mural)"
                        class="img-fluid"
                        style="max-width: 112px"
                      >
                      <img
                        v-else
                        :src="require('@/assets/images/app/mural-icon.png')"
                        class="img-fluid"
                      >
                      <h3 class="menu_font_color">Mural</h3>
                    </a>
                  </div>
                  <div
                    class="fd-mode-opcoes-item-dicas alert alert-info"
                    role="alert"
                    style="display: none"
                  >
                    <i class="fas fa-info-circle" /> Publique avisos no mural de
                    todos usuários da plataforma.
                    <br>
                  </div>
                </div>

                <div
                  v-if="$store.state.fastPermissoes.administrador == 'S'"
                  class="fd-mode-opcoes-item position-relative"
                >
                  <div class="row">
                    <a
                      :href="
                        '/plataforma/' +
                          $route.params.id_plataforma +
                          '/admin/lives'
                      "
                      class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                      :style="{
                        backgroundColor: $store.state.fastPersonalizacao
                          .menu_background_color
                          ? $store.state.fastPersonalizacao
                            .menu_background_color
                          : '#fff',
                      }"
                      @click.prevent="$router.push('/plataforma/' +
                        $route.params.id_plataforma +
                        '/admin/lives')"
                    >
                      <img
                        v-if="
                          ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_minhas_lives)
                        "
                        :src="
                        ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_minhas_lives)
                        "
                        class="img-fluid"
                        style="max-width: 112px"
                      >
                      <img
                        v-else
                        :src="require('@/assets/images/app/usuarios-icon.png')"
                        class="img-fluid"
                      >
                      <h3
                        v-if="$store.state.fastPersonalizacao.minhas_lives_nome"
                        class="mt-0 mt-sm-1 menu_font_color"
                      >
                        {{ $store.state.fastPersonalizacao.minhas_lives_nome }}
                      </h3>
                      <h3
                        v-else
                        class="mt-0 mt-sm-1 menu_font_color"
                      >
                        Minhas lives
                      </h3>
                    </a>
                  </div>                  
                </div>

                <!-- financeiro -->
                <div
                  v-if="$store.state.fastPermissoes.edita_financeiro == 'S'"
                  class="fd-mode-opcoes-item position-relative"
                >
                  <div class="row align-items-center">
                    <a
                      :href="
                        '/plataforma/' +
                          $route.params.id_plataforma +
                          '/admin/financeiro'
                      "
                      class="col-sm-12 col-md-12 col-lg-8 con-classroom-anotacoes-desativado shadow rounded"
                      :style="{
                        backgroundColor: $store.state.fastPersonalizacao
                          .menu_background_color
                          ? $store.state.fastPersonalizacao
                            .menu_background_color
                          : '#fff',
                      }"
                      @click.prevent="$router.push('/plataforma/' +
                        $route.params.id_plataforma +
                        '/admin/financeiro')"
                    >
                      <div
                        class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                      >
                        <img
                          :src="imageSiteFinanceiro"
                          class="img-fluid"
                        >
                        <h3 class="menu_font_color">Financeiro</h3>
                      </div>
                    </a>
                    <div
                      class="col-sm-12 col-md-12 col-lg-4 fd-mode-opcoes-item-desc d-none"
                    >
                      <h3>EM BREVE</h3>
                    </div>

                    <div
                      id="collapseBoxFinanceiro"
                      class="col-sm-12 col-md-12 col-lg-5 fd-mode-opcoes-item-menu"
                    >
                      <ul class="fd-mode-opcoes-item-menu-list d-none">
                        <li class="fd-mode-opcoes-item-menu-list-item">
                          <a href="#">Minhas Requisições/Compras</a>
                        </li>
                        <li class="fd-mode-opcoes-item-menu-list-item">
                          <a href="#">Histórico Financeiro</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- suporte -->
                <div class="fd-mode-opcoes-item position-relative">
                  <div class="row align-items-center">
                    <a
                      :href="
                        '/plataforma/' +
                          $route.params.id_plataforma +
                          '/admin/suporte'
                      "
                      class="col-sm-12 col-md-12 col-lg-8 con-classroom-anotacoes-desativado shadow rounded"
                      :style="{
                        backgroundColor: $store.state.fastPersonalizacao
                          .menu_background_color
                          ? $store.state.fastPersonalizacao
                            .menu_background_color
                          : '#fff',
                      }"
                      @click.prevent="$router.push('/plataforma/' +
                        $route.params.id_plataforma +
                        '/admin/suporte')"
                    >
                      <div
                        class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                      >
                        <img
                          :src="require('@/assets/images/app/atendimento-icon.png')"
                          class="img-fluid"
                        >
                        <h3 class="menu_font_color">Suporte</h3>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="fd-mode-opcoes-footer">
                  <a
                    id="linkVoltarPlataforma"
                    class="btn btn-red-hollow"
                    :href="'/plataforma/' + $route.params.id_plataforma"
                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
                  >
                    <b-icon-arrow-return-left /> Voltar
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import imageSiteMatrizIcon from "@/assets/images/app/matriz-icon.png";
import imageSiteCursosIcon from "@/assets/images/app/cursos-icon.png";
import imageSiteUsuariosIcon from "@/assets/images/app/usuarios-icon.png";
import imageSitePersonalizarIcon from "@/assets/images/app/personalizar-icon.png";
import imageSiteOndemandIcon from "@/assets/images/app/ondemand-icon.png";
import imageSiteMuralIcon from "@/assets/images/app/mural-icon.png";
import imageSiteFinanceiro from "@/assets/images/app/financeiro-icon.png";
import imageSiteIntegracoes from "@/assets/images/app/menu_integracoes_icon.png";

export default {
  name: "HomeInternoAdmin",

  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      //ImageSiteFormEffect01,
      imageSiteMatrizIcon,
      imageSiteCursosIcon,
      imageSiteUsuariosIcon,
      imageSitePersonalizarIcon,
      imageSiteOndemandIcon,
      imageSiteMuralIcon,
      imageSiteFinanceiro,
      imageSiteIntegracoes,
    };
  },
  computed: {
    HomeInternoAdminMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAdminMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {          
          // Se foi personalizado aplica cor nos textos
          if (this.$store.state.fastPersonalizacao.aluno_font_color) {
            Array.from(document.querySelectorAll(".aluno_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.aluno_font_color));
          }
          // Se foi personalizado aplica cor nos textos dos menus
          if (this.$store.state.fastPersonalizacao.menu_font_color) {
            Array.from(document.querySelectorAll(".menu_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.menu_font_color));
          }
          this.$store.state.fastCarregando = false;          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {},
};
</script>

<style></style>